body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  @font-face{
    font-family: "Cormorant";
    src: local("Cormorant"),
    url("../public/fonts/Cormorant/Cormorant-Regular.ttf");
  }

  @font-face{
    font-family: "CormorantBold";
    src: local("CormorantBold"),
    url("../public/fonts/Cormorant/Cormorant-Bold.ttf");
  }